import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import BaseService from '../../services/BaseService';

const PrivateRoute = ({component: Component, ...rest}) => {
    const baseService = new BaseService();

    return (
        <Route {...rest} render={props => (
            baseService.loggedIn()
                ? <Component {...props} user={baseService.decodeToken()} />
                : <Redirect to={{pathname: '/login', state: {from: props.location}}} />
        )}/>
    );
};

export default PrivateRoute;