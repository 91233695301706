import React, { Component, Fragment } from 'react';
import { withLayout } from '../../HOC/WithLayout';
import { isAdmin } from '../../_utils/user';
import './workbench.scss';
import ProfileImageManager from '../../ProfileImageManager/ProfileImageManager';
import NotificationWrapper from '../../_wrappers/NotificationWrapper';

class WorkbenchPage extends Component {
    images = [
        {
            idImage: 4,
            name: "Persona Postman paleta caracteres 2",
            alt: "persona postman2",
            active: true,
            tmsCreated: 1541264095,
            path: "http://tropical-api.site/assets/images/profiles/5bddd2df70025031807943.jpg",
            size: 79698
        },
        {
            idImage: 5,
            name: "Persona Postman paleta caracteres 2",
            alt: "persona postman2",
            active: true,
            tmsCreated: 1541268130,
            path: "http://tropical-api.site/assets/images/profiles/5bdde2a2c3fa4937589404.jpg",
            size: 51839
        },
        {
            idImage: 6,
            name: "Persona Postman paleta caracteres 2",
            alt: "persona postman2",
            active: true,
            tmsCreated: 1541268366,
            path: "http://tropical-api.site/assets/images/profiles/5bdde38e7d49c785688438.png",
            size: 35880
        },
        {
            idImage: 8,
            name: "1125",
            alt: "1125",
            active: true,
            tmsCreated: 1544984521,
            path: "http://tropical-api.site/assets/images/profiles/5c1697c932f0a948855015.jpg",
            size: 16187
        }
    ];

    componentDidMount() {
        if (!isAdmin(this.props.user)) {
            this.props.history.replace({pathname: '/unauthorized', state: 'recurso no disponible'});
        }
    }

    handleRemoveImage = () => {
        this.notification.board.addNotification({
            title: '¡Imagen eliminada!',
            content: 'La imagen se ha eliminado correctamente',
            className: 'warning'
        });
    };

    render() {
        return <Fragment>
            <ProfileImageManager images={this.images}
                                 onRemoveImage={this.handleRemoveImage}
            />
            <NotificationWrapper ref={ref => this.notification = ref } />
        </Fragment>;
    }
}
export default withLayout(WorkbenchPage);