import React, { Component } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Slide from '../Swiper/Slide';
import SwiperWrapper from '../Swiper/SwiperWrapper';
import './lightbox-wrapper.scss';

export default class LightboxWrapper extends Component {
    state = {
        photoIndex: 0,
        isOpen: false
    };

    open(photoIndex) {
        this.setState({photoIndex, isOpen: true});
    }

    render() {
        return (
            <div className="lightbox-wrapper">
                <SwiperWrapper className="lightbox-wrapper__swiper"
                               initialize={!!this.props.images.length}
                               extraConfig={{slidesPerView: 5, spaceBetween: 5, grabCursor: false}}
                               ref={ref => this.swiper = ref}>
                    {this.props.images.map((image, index) => (
                        <Slide key={index}>
                            <img className="lightbox-wrapper__image"
                                 src={image}
                                 alt="Figure"
                                 onClick={() => this.open(index)} />
                        </Slide>
                    ))}
                </SwiperWrapper>

                {this.state.isOpen && (
                    <Lightbox mainSrc={this.props.images[this.state.photoIndex]}
                              nextSrc={this.props.images[(this.state.photoIndex + 1) % this.props.images.length]}
                              prevSrc={this.props.images[(this.state.photoIndex + this.props.images.length - 1) % this.props.images.length]}
                              onCloseRequest={() => this.setState({isOpen: false})}
                              onMovePrevRequest={() =>
                                  this.setState({
                                      photoIndex: (this.state.photoIndex + this.props.images.length - 1) % this.props.images.length,
                                  })
                              }
                              onMoveNextRequest={() =>
                                  this.setState({
                                      photoIndex: (this.state.photoIndex + 1) % this.props.images.length,
                                  })
                              }
                    />
                )}
            </div>
        );
    }
}