import BaseService from './BaseService';

export default class ProfileService extends BaseService {

    findAll(page = 1) {
        const endpoint = 'profiles';

        return this
            .fetch(`${this.API_URL}${endpoint}`, {method: 'GET'}, {page})
            .then(response => response);
    }

    search(page = 1, filters = {}) {
        const endpoint = 'profiles/search';

        return this
            .fetch(`${this.API_URL}${endpoint}`, {method: 'GET'}, {page, ...filters})
            .then(response => response);
    }

    findById(idProfile) {
        const endpoint = `profiles/${idProfile}`;

        return this
            .fetch(`${this.API_URL}${endpoint}`, {method: 'GET'})
            .then(response => response);
    }

    downloadPDF(idProfile) {
        const endpoint = `profiles/${idProfile}/download-pdf`;

        return this
            .fetch(`${this.API_URL}${endpoint}`, {method: 'GET'}, null, {'Accept': 'application/pdf', 'Content-Type': 'application/pdf'}, 'blob')
            .then(response => response);
    }

    downloadDetailPDF(idProfile) {
        const endpoint = `profiles/${idProfile}/download-detail-pdf`;

        return this
            .fetch(`${this.API_URL}${endpoint}`, {method: 'GET'}, null, {'Accept': 'application/pdf', 'Content-Type': 'application/pdf'}, 'blob')
            .then(response => response);
    }

    addFavoriteProject(idProfile, idProject) {
        const endpoint = `profiles/${idProfile}/favorite/${idProject}/add`;

        return this
            .fetch(`${this.API_URL}${endpoint}`, {method: 'PUT'})
            .then(response => response);
    }

    removeFavoriteProject(idProfile, idProject) {
        const endpoint = `profiles/${idProfile}/favorite/${idProject}/remove`;

        return this
            .fetch(`${this.API_URL}${endpoint}`, {method: 'PUT'})
            .then(response => response);
    }

    addSelectedProject(idProfile, idProject) {
        const endpoint = `profiles/${idProfile}/selected/${idProject}/add`;

        return this
            .fetch(`${this.API_URL}${endpoint}`, {method: 'PUT'})
            .then(response => response);
    }

    removeSelectedProject(idProfile, idProject) {
        const endpoint = `profiles/${idProfile}/selected/${idProject}/remove`;

        return this
            .fetch(`${this.API_URL}${endpoint}`, {method: 'PUT'})
            .then(response => response);
    }

    updateActive(idProfile, active) {
        const endpoint = `profiles/${idProfile}/active`;
        const data = new URLSearchParams();
        data.append('active', active);

        return this
            .fetch(`${this.API_URL}${endpoint}`,
                {
                    method: 'PUT',
                    body: data
                },
                null,
                {'Content-Type': 'application/x-www-form-urlencoded'}
            )
            .then(response => response);
    }

    update(idProfile, formData) {
        const endpoint = `profiles/${idProfile}`;
        const data = new URLSearchParams();

        Object.keys(formData).forEach(key => data.append(key, formData[key]));

        return this
            .fetch(`${this.API_URL}${endpoint}`,
                {
                    method: 'PUT',
                    body: data
                },
                null,
                {'Content-Type': 'application/x-www-form-urlencoded'}
            )
            .then(response => response);
    }
}