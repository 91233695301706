import React, { Component, Fragment } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AppProvider from './context/AppProvider';
import './App.scss';
import AppRoutes from './routes/AppRoutes';
require('dotenv').config();

export default class App extends Component {
    render() {
        return (
            <AppProvider>
                <Router>
                    <Fragment>
                        <AppRoutes />
                    </Fragment>
                </Router>
            </AppProvider>
        );
    }
}
