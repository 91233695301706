import React, { Component, Fragment } from 'react';
import { AppContext } from '../../../context/AppProvider';
import Breadcrumb from '../../Breadcrumb/Breadcrumb';
import { withLayout } from '../../HOC/WithLayout';
import Hero from '../../Hero/Hero';
import ModalWrapper from '../../ModalWrapper/ModalWrapper';
import NotificationWrapper from '../../_wrappers/NotificationWrapper';
import Icon from '../../Icon/Icon';
import LoadMaskWrapper from '../../_wrappers/LoadMaskWrapper';
import Moment from 'react-moment';
import Button from '../../Button/Button';
import ContentSection from '../../ContentSection/ContentSection';
import AsideBlock from '../../AsideBlock/AsideBlock';
import ProjectSelector from '../../Project/ProjectSelector';
import TableCellLink from '../../TableCellLink/TableCellLink';

class CustomerPage extends Component {
    state = {
        customer: {},
        modalAddToProjectOpen: false,
        loading: true
    };
    idCustomer = this.props.match.params.idCustomer;

    static contextType = AppContext;

    componentDidMount() {
        this.findCustomer(this.idCustomer);
    }

    findCustomer(idCustomer) {
        this.context.services.user
            .findById(idCustomer)
            .then(customer => {
                if (!customer) {
                    this.setState({loading: false});
                    return;
                }

                this.setState({
                    customer: customer,
                    loading: false
                });
            }).catch(reason => {
                this.props.history.push({pathname: '/unauthorized', state: reason.message});
            });
    }

    openAddToProjectModal = () => {
        this.setState({modalAddToProjectOpen: true});
    };

    closeAddToProjectModal = () => {
        this.setState({modalAddToProjectOpen: false}, () => this.findCustomer(this.idCustomer));
    };

    toggleEnable = (event, idUser, enable) => {
        event.preventDefault();

        this.context.services.user
            .updateEnable(idUser, enable)
            .then(response => {
                if (!response.success) return;

                if (enable) {
                    this.notification.board.addNotification({
                        title: 'Usuario Actualizado',
                        content: 'Se permite el acceso a la aplicación a este usuario',
                        className: 'success'
                    });
                } else {
                    this.notification.board.addNotification({
                        title: 'Usuario Actualizado',
                        content: 'Se prohibe el acceso a la aplicación a este usuario',
                        className: 'warning'
                    });
                }

                this.findCustomer(idUser);
            });
    };

    render() {
        return (
            <Fragment>
                <Hero title="Ficha de cliente" subtitle={this.state.customer.username} />
                <section className="section">
                    <div className="container">
                        <Breadcrumb model={[
                            {name: 'Listado de clientes', link: '/customers/'},
                            {name: `${this.state.customer.username}`, link: './', active: true}]}
                        />
                        <div className="columns">
                            <div className="column is-one-third">
                                <div className="card">
                                    <div className="card-content p-2">
                                        <div className="media">
                                            <div className="media-left">
                                                <figure style={{fontSize: 35}} className="has-text-grey-light">
                                                    <Icon icon="user" />
                                                </figure>
                                            </div>
                                            <div className="media-content">
                                                <p className="title is-5">{this.state.customer.username}</p>
                                                <p className="subtitle is-6">{this.state.customer.email}</p>
                                            </div>
                                        </div>
                                        <AsideBlock title="Acciones globales">
                                            <Button icon="random"
                                                    extraClasses="is-info"
                                                    text="Asociar a proyecto"
                                                    onClick={this.openAddToProjectModal}
                                            />
                                            <div className="mt-3">
                                                <p className="mb-1 has-text-grey">
                                                    Acceso {this.state.customer.enabled ? 'permitido' : 'bloqueado'}
                                                </p>
                                                <Button icon="ban"
                                                        extraClasses={this.state.customer.enabled ? 'is-warning' : 'is-success'}
                                                        text={this.state.customer.enabled ? 'Bloquear acceso' : 'Permitir acceso'}
                                                        onClick={event => this.toggleEnable(event, this.state.customer.idUser, !this.state.customer.enabled)}
                                                />
                                            </div>
                                            <ModalWrapper title="Asociar a proyecto"
                                                          open={this.state.modalAddToProjectOpen}
                                                          onClose={this.closeAddToProjectModal}>
                                                <ProjectSelector idUser={this.state.customer.idUser}
                                                                 onUpdateUser={() => this.findCustomer(this.idCustomer)}
                                                />
                                            </ModalWrapper>
                                        </AsideBlock>
                                    </div>
                                </div>
                            </div>
                            <div className="column is-two-thirds mb-3">
                                <ContentSection title="Proyectos">
                                    <table className="table is-bordered is-hoverable is-fullwidth">
                                        <thead>
                                            <tr>
                                                <th>Id</th>
                                                <th>Nombre de proyecto</th>
                                                <th>Fecha de creación</th>
                                                <th>Activo</th>
                                                <th>Perfiles favoritos</th>
                                                <th>Perfiles seleccionados</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.customer.projects && this.state.customer.projects.length
                                                ? this.state.customer.projects.map(project => (
                                                    <tr key={project.idProject}>
                                                        <TableCellLink to={`/projects/${project.idProject}`}>
                                                            {project.idProject}
                                                        </TableCellLink>
                                                        <TableCellLink to={`/projects/${project.idProject}`}>
                                                            {project.name}
                                                        </TableCellLink>
                                                        <TableCellLink to={`/projects/${project.idProject}`}>
                                                            <Moment format="DD/MM/YYYY HH:mm" unix>{project.tmsCreated}</Moment>
                                                        </TableCellLink>
                                                        <TableCellLink to={`/projects/${project.idProject}`}>
                                                            {project.active ? <Icon icon="check" /> : <Icon icon="times" />}
                                                        </TableCellLink>
                                                        <TableCellLink to={`/projects/${project.idProject}`}>
                                                            {project.favorites.length}
                                                        </TableCellLink>
                                                        <TableCellLink to={`/projects/${project.idProject}`}>
                                                            {project.selected.length}
                                                        </TableCellLink>
                                                    </tr>
                                                ))
                                                :null
                                            }
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td className="has-text-grey" colSpan={1000}>
                                                    {this.state.customer.projects && this.state.customer.projects.length
                                                        ? `Asignado en ${this.state.customer.projects.length} proyectos`
                                                        : 'Este cliente no está asígnado a ningún proyecto aun…'
                                                    }
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </ContentSection>
                            </div>
                        </div>
                    </div>
                </section>
                <NotificationWrapper ref={ref => this.notification = ref } />
                <LoadMaskWrapper visible={this.state.loading}
                                 backgroundLayerStyle={{ position: 'fixed' }}>
                    <h3>Cargando cliente…</h3>
                </LoadMaskWrapper>
            </Fragment>
        );
    }
}
export default withLayout(CustomerPage);