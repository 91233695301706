import React, { Component, Fragment } from 'react';
import Swiper from 'swiper/dist/js/swiper';
import './swiper-wrapper.scss';

export default class SwiperWrapper extends Component {
    swiper = {};

    static defaultProps = {
        initialize: false,
        hasNavigation: true,
        hasPagination: false,
        hasScrollbar: true,
        config: {
            direction: 'horizontal',
            loop: false,
            grabCursor: true,
            runCallbacksOnInit: true,
            pagination: {
                el: '.swiper-pagination',
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            scrollbar: {
                el: '.swiper-scrollbar',
            },
            init: false
        },
        extraConfig: {}
    };

    componentDidMount() {
        this.build();
    }

    componentDidUpdate() {
        if (this.props.initialize) {
            this.swiper.init();
        }
    }

    build = () => {
        this.swiper = new Swiper(this.swiperRef, {...this.props.config, ...this.props.extraConfig});
        if (this.props.initialize) {
            this.swiper.init();
        }
    };

    rebuild() {
        this.swiper.destroy(true, true);
        this.build();
    }

    render() {
        return (
            <div className={`swiper-container ${this.props.className ? this.props.className : ''}`}
                 ref={ref => this.swiperRef = ref}>
                <div className="swiper-wrapper">
                    {this.props.children}
                </div>

                {this.props.hasPagination
                    ? <div className="swiper-pagination" />
                    : null
                }

                {this.props.hasNavigation
                    ? <Fragment>
                        <div className="swiper-button-prev" />
                        <div className="swiper-button-next" />
                    </Fragment>
                    : null
                }

                {this.props.hasScrollbar
                    ? <div className="swiper-scrollbar" />
                    : null
                }
            </div>
        );
    }
}