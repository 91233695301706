import React, { Component } from 'react';
import LoginForm from '../../Login/LoginForm';
import { AppContext } from '../../../context/AppProvider';
import tropicalLogo from '../../../assets/images/tropical-logo.jpg';

export default class LoginPage extends Component {
    static contextType = AppContext;

    redirect = response => {
        const from = this.props.location.state
            ? this.props.location.state.from.pathname
            : '/';
        this.props.history.push(from);
    };

    render() {
        return (
            <section className="section">
                <div className="container mt-3">
                    <div className="columns">
                        <div className="column is-one-third is-offset-one-third">
                            <figure className="image">
                                <img src={tropicalLogo} width="300"
                                     height="auto" alt="Tropical Casting" />
                            </figure>
                        </div>
                    </div>

                    <div className="columns is-centered">
                        <div className="column is-one-third">
                            <LoginForm onSuccess={this.redirect} />
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}