import React, { Component, Fragment } from 'react';
import { AppContext } from '../../../context/AppProvider';
import Pagination from '../../Pagination/Pagination';
import ProfileCard from '../../ProfileCard/ProfileCard';
import { withLayout } from '../../HOC/WithLayout';
import Hero from '../../Hero/Hero';
import ProfileFilters from '../../ProfileFilters/ProfileFilters';
import LoadMaskWrapper from '../../_wrappers/LoadMaskWrapper';
import Icon from '../../Icon/Icon';
import NewProfileForm from '../../ProfileForm/NewProfileForm';
import Button from '../../Button/Button';
import FormModalWrapper from '../../ModalWrapper/FormModalWrapper';
import NotificationWrapper from '../../_wrappers/NotificationWrapper';

class ProfilesPage extends Component {
    state = {
        profiles: [],
        pagination: {},
        loading: true,
        modalAddProfileOpen: false
    };

    static contextType = AppContext;

    componentDidMount() {
        this.findProfiles();
    }

    findProfiles(page = 1) {
        this.setState({loading: true});

        const filters = this.adaptFiltersToRequest(this.profileFilters.getStoredFilters());

        this.context.services.profile
            .search(page, filters)
            .then(response => {
                if (!response) {
                    this.setState({
                        profiles: [],
                        pagination: {},
                        loading: false
                    });
                    return;
                }

                this.setState({
                    profiles: response.items,
                    pagination: {
                        pageRange: response.pageRange,
                        currentPage: response.currentPage,
                        itemsPerPage: response.itemsPerPage,
                        totalItems: response.totalItems
                    },
                    loading: false
                });
            }).catch(reason => {
                this.props.history.push({pathname: '/unauthorized', state: reason.message});
            });
    }

    handlePageChange = page => {
        this.findProfiles(page);
    };

    filterProfiles = filters => {
        this.findProfiles();
    };

    adaptFiltersToRequest(filters) {
        if (!filters) return;
        let adaptedFilters = {...filters};

        if (filters.hasOwnProperty('age') && filters.age) {
            adaptedFilters.ageMin = filters.age[0];
            adaptedFilters.ageMax = filters.age[1];
            delete adaptedFilters.age;
        }

        if (filters.hasOwnProperty('weight') && filters.weight) {
            adaptedFilters.weightMin = filters.weight[0];
            adaptedFilters.weightMax = filters.weight[1];
            delete adaptedFilters.weight;
        }

        if (filters.hasOwnProperty('height') && filters.height) {
            adaptedFilters.heightMin = filters.height[0];
            adaptedFilters.heightMax = filters.height[1];
            delete adaptedFilters.height;
        }

        if (filters.hasOwnProperty('spokenLanguages') && filters.spokenLanguages) {
            adaptedFilters.spokenLanguages = filters.spokenLanguages.join(',');
        }

        for (let property in adaptedFilters) {
            if (adaptedFilters[property] === null || adaptedFilters[property] === undefined) {
                delete adaptedFilters[property];
            }
        }

        return adaptedFilters;
    }

    openAddProfileModal = () => {
        this.setState({modalAddProfileOpen: true});
    };

    closeAddProfileModal = () => {
        this.setState({modalAddProfileOpen: false});
    };

    addProfile = formData => {
        this.setState({sending: true});
        this.context.services.user
            .createProfile(formData.name, formData.surname, formData.email)
            .then(response => {
                this.notification.board.addNotification({
                    title: '¡Todo bien!',
                    content: 'Nuevo perfil creado',
                    className: 'success'
                });
                this.closeAddProfileModal();
                this.findProfiles();
                this.setState({sending: false});
            })
            .catch(reason => {
                this.notification.board.addNotification({
                    title: 'Error creando perfil',
                    content: reason.message,
                    className: 'danger'
                });
                this.setState({sending: false});
            });
    };

    render() {
        return (
            <Fragment>
                <Hero title="Listado de perfiles">
                    <Button extraClass="is-outlined"
                            text="Nuevo Perfil"
                            icon="plus"
                            onClick={this.openAddProfileModal}
                    />
                </Hero>
                <section className="section">
                    <div className="container">
                        <div className="columns">
                            <div className="column is-one-third">
                                <ProfileFilters ref={ref => this.profileFilters = ref}
                                                onFilter={this.filterProfiles} />
                            </div>
                            <div className="column is-two-thirds">
                                {this.state.profiles.length
                                    ? <Fragment>
                                        <Pagination className="mb-2"
                                                    {...this.state.pagination}
                                                    onPageChange={this.handlePageChange}
                                        />
                                        <div className="columns is-multiline">
                                            {this.state.profiles.map(profile => (
                                                <div key={profile.idProfile}
                                                     className="column is-one-third">
                                                    <ProfileCard profile={profile} />
                                                </div>
                                            ))}
                                        </div>
                                        <Pagination {...this.state.pagination}
                                                    onPageChange={this.handlePageChange}
                                        />
                                    </Fragment>
                                    : <div className="message is-info">
                                        <div className="message-body">
                                            <p><Icon icon="clock" /> No se encuentran perfiles…</p>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </section>
                <LoadMaskWrapper visible={this.state.loading}
                                 backgroundLayerStyle={{ position: 'fixed' }}>
                    <h3>Cargando perfiles…</h3>
                </LoadMaskWrapper>
                <FormModalWrapper title="Añade un perfil"
                                  open={this.state.modalAddProfileOpen}
                                  onClose={this.closeAddProfileModal}>
                    <NewProfileForm sending={this.state.sending}
                                    onSubmit={this.addProfile}
                                    onCancel={this.closeAddProfileModal}
                    />
                </FormModalWrapper>
                <NotificationWrapper ref={ref => this.notification = ref } />
            </Fragment>
        );
    }
}
export default withLayout(ProfilesPage);