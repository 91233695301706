import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from '../Button/Button';
import SortableListWrapper from '../_wrappers/SortableListWrapper/SortableListWrapper';
import { AppContext } from '../../context/AppProvider';
import Lightbox from 'react-image-lightbox';
import './profile-image-manager.scss';
import { debounce } from 'throttle-debounce';

export default class ProfileImageManager extends Component {
    state = {
        photoIndex: 0,
        isOpen: false,
        debouncedSortEnd: debounce(400, false, this.props.onSortEnd)
    };

    static defaultProps = {
        onRemoveImage: () => {}
    };

    static propTypes = {
        images: PropTypes.arrayOf(PropTypes.object).isRequired
    };

    static contextType = AppContext;

    openLightBox(photoIndex) {
        this.setState({photoIndex, isOpen: true});
    };

    onSortEnd = item => {
        this.context.services.image
            .updateOrder(item.idImage, item.order)
            .then(response => {
                if (!response) return;

                this.state.debouncedSortEnd();
            });
    };

    removeImage = idImage => {
        this.context.services.image
            .remove(idImage)
            .then(response => {
                if (!response) return;

                this.props.onRemoveImage();
            });
    };

    buildItems(images) {
        return images.map((image, index) => {
            return {
                idImage: image.idImage,
                render: () => (
                    <div className="image-manager__item">
                        <figure className="image-manager__image">
                            <img src={image.path}
                                 alt={image.alt}
                                 onClick={() => this.openLightBox(index)}
                            />
                        </figure>
                        <div className="image-manager__actions">
                            <Button icon="trash"
                                      onClick={() => this.removeImage(image.idImage)}
                            />
                        </div>
                    </div>
                )
            }
        });
    }

    render() {
        const imagesUrl = this.props.images.map(image => image.path);
        
        return (
            <Fragment>
                <section className="image-manager">
                    <SortableListWrapper items={this.buildItems(this.props.images)}
                                         idProperty="idImage"
                                         onSortEnd={this.onSortEnd}
                    />
                </section>
                {this.state.isOpen && (
                    <Lightbox mainSrc={imagesUrl[this.state.photoIndex]}
                              nextSrc={imagesUrl[(this.state.photoIndex + 1) % imagesUrl.length]}
                              prevSrc={imagesUrl[(this.state.photoIndex + imagesUrl.length - 1) % imagesUrl.length]}
                              onCloseRequest={() => this.setState({isOpen: false})}
                              onMovePrevRequest={() =>
                                  this.setState({
                                      photoIndex: (this.state.photoIndex + imagesUrl.length - 1) % imagesUrl.length,
                                  })
                              }
                              onMoveNextRequest={() =>
                                  this.setState({
                                      photoIndex: (this.state.photoIndex + 1) % imagesUrl.length,
                                  })
                              }
                    />
                )}
            </Fragment>
        );
    }
}