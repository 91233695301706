import React, { Component, Fragment } from 'react';
import { AppContext } from '../../../context/AppProvider';
import Pagination from '../../Pagination/Pagination';
import { withLayout } from '../../HOC/WithLayout';
import Hero from '../../Hero/Hero';
import LoadMaskWrapper from '../../_wrappers/LoadMaskWrapper';
import Button from '../../Button/Button';
import NotificationWrapper from '../../_wrappers/NotificationWrapper';
import CustomerForm from '../../CustomerForm/CustomerForm';
import SearchForm from '../../SearchForm/SearchForm';
import FormModalWrapper from '../../ModalWrapper/FormModalWrapper';
import { isAdmin } from '../../_utils/user';
import Toggle from '../../Toggle/Toggle';
import TableCellLink from '../../TableCellLink/TableCellLink';

class CustomersPage extends Component {
    state = {
        customers: [],
        pagination: {},
        loading: true,
        modalAddCustomerOpen: false,
        modalUpdateCustomerOpen: false,
        currentEditCustomer: null,
        sending: false
    };

    static contextType = AppContext;

    componentDidMount() {
        this.findCustomers();
    }

    findCustomers(page = 1, q = null) {
        this.context.services.user
            .search(page, q)
            .then(response => {
                if (!response) {
                    this.setState({
                        customers: [],
                        pagination: {},
                        loading: false
                    });
                    return;
                }

                this.setState({
                    customers: response.items,
                    pagination: {
                        pageRange: response.pageRange,
                        currentPage: response.currentPage,
                        itemsPerPage: response.itemsPerPage,
                        totalItems: response.totalItems
                    },
                    loading: false
                });
            }).catch(reason => {
                this.props.history.push({pathname: '/unauthorized', state: reason.message});
            });
    }

    handlePageChange = page => {
        this.findCustomers(page);
    };

    openAddCustomerModal = () => {
        this.setState({modalAddCustomerOpen: true});
    };

    closeAddCustomerModal = () => {
        this.setState({modalAddCustomerOpen: false});
    };

    openUpdateCustomerModal = customer => {
        this.setState({
            currentEditCustomer: {
                idUser: customer.idUser,
                username: customer.username,
                email: customer.email,
                phone: customer.phone
            },
            modalUpdateCustomerOpen: true
        });
    };

    closeUpdateCustomerModal = () => {
        this.setState({modalUpdateCustomerOpen: false});
    };

    addCustomer = formData => {
        this.setState({sending: true});
        this.context.services.user
            .createCustomer(formData.username, formData.email, formData.phone)
            .then(response => {
                this.notification.board.addNotification({
                    title: '¡Todo bien!',
                    content: 'Nuevo cliente creado',
                    className: 'success'
                });
                this.closeAddCustomerModal();
                this.findCustomers();
                this.setState({sending: false});
            })
            .catch(reason => {
                this.notification.board.addNotification({
                    title: 'Error creando cliente',
                    content: reason.message,
                    className: 'danger'
                });
                this.setState({sending: false});
            });
    };

    updateCustomer = formData => {
        this.setState({sending: true});
        this.context.services.user
            .updateCustomer(formData.idUser, formData.phone)
            .then(response => {
                this.notification.board.addNotification({
                    title: '¡Todo bien!',
                    content: 'El cliente ha sido actualizado',
                    className: 'success'
                });
                this.closeUpdateCustomerModal();
                this.findCustomers();
                this.setState({sending: false});
            })
            .catch(reason => {
                this.notification.board.addNotification({
                    title: 'Error actualizando cliente',
                    content: reason.message,
                    className: 'danger'
                });
                this.setState({sending: false});
            });
    };

    toggleEnable = (idUser, enable) => {
        this.context.services.user
            .updateEnable(idUser, !enable)
            .then(response => {
                if (!response.success) return;

                if (!enable) {
                    this.notification.board.addNotification({
                        title: 'Usuario Actualizado',
                        content: 'Se permite el acceso a la aplicación a este usuario',
                        className: 'success'
                    });
                } else {
                    this.notification.board.addNotification({
                        title: 'Usuario Actualizado',
                        content: 'Se prohibe el acceso a la aplicación a este usuario',
                        className: 'warning'
                    });
                }

                this.findCustomers();
            });
    };

    render() {
        return (
            <Fragment>
                <Hero title="Listado de clientes">
                    <Button extraClass="is-outlined"
                            text="Nuevo Cliente"
                            icon="plus"
                            onClick={this.openAddCustomerModal}
                    />
                </Hero>
                <section className="section">
                    <div className="container">
                        <div className="columns">
                            <div className="column is-one-third is-offset-one-third">
                                <SearchForm placeholder="Buscar clientes por usuario o E-mail…"
                                            onSubmit={q => this.findCustomers(1, q)}
                                />
                            </div>
                        </div>
                        <Pagination className="mb-2"
                                    {...this.state.pagination}
                                    onPageChange={this.handlePageChange}
                        />
                        <div className="responsive-table-wrapper">
                            <table className="table is-bordered is-hoverable is-fullwidth">
                                <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Nombre de usuario</th>
                                        <th>E-mail</th>
                                        <th>Teléfono</th>
                                        {isAdmin(this.props.user) &&
                                        <Fragment>
                                            <th className="has-text-centered">Acceso permitido</th>
                                            <th style={{textAlign: 'center'}}>Acciones</th>
                                        </Fragment>
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.customers.map(customer => (
                                        <tr key={customer.idUser}>
                                            <TableCellLink to={`/customers/${customer.idUser}`}>
                                                {customer.idUser}
                                            </TableCellLink>
                                            <TableCellLink to={`/customers/${customer.idUser}`}>
                                                {customer.username}
                                            </TableCellLink>
                                            <TableCellLink>
                                                <a href={`mailto:${customer.email}`}
                                                   target="_blank"
                                                   rel="noopener noreferrer">
                                                    {customer.email}
                                                </a>
                                            </TableCellLink>
                                            <TableCellLink>
                                                <a href={`tel:${customer.phone}`}>
                                                    {customer.phone}
                                                </a>
                                            </TableCellLink>
                                            {isAdmin(this.props.user) &&
                                            <Fragment>
                                                <td className="has-text-centered">
                                                    <Toggle checked={customer.enabled}
                                                            onChange={() => this.toggleEnable(customer.idUser, customer.enabled)}
                                                    />
                                                </td>
                                                <td style={{textAlign: 'center'}}>
                                                    <Button icon="edit"
                                                            extraClasses="is-small is-light"
                                                            text="Actualizar"
                                                            onClick={() => this.openUpdateCustomerModal(customer)} />
                                                </td>
                                            </Fragment>
                                            }
                                        </tr>
                                    ))}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td className="has-text-grey" colSpan={1000}>
                                            {this.state.customers && this.state.customers.length
                                                ? `${this.state.customers.length} clientes`
                                                : 'No se encuentran resultados…'
                                            }
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        <Pagination {...this.state.pagination}
                                    onPageChange={this.handlePageChange}
                        />
                    </div>
                </section>
                <LoadMaskWrapper visible={this.state.loading}
                                 backgroundLayerStyle={{ position: 'fixed' }}>
                    <h3>Cargando clientes…</h3>
                </LoadMaskWrapper>
                <FormModalWrapper title="Añade un cliente"
                              open={this.state.modalAddCustomerOpen}
                              onClose={this.closeAddCustomerModal}>
                    <CustomerForm sending={this.state.sending}
                                  onSubmit={this.addCustomer}
                                  onCancel={this.closeAddCustomerModal}
                    />
                </FormModalWrapper>
                <FormModalWrapper title="Edita el cliente"
                                  open={this.state.modalUpdateCustomerOpen}
                                  onClose={this.closeUpdateCustomerModal}>
                    <CustomerForm sending={this.state.sending}
                                  onSubmit={this.updateCustomer}
                                  data={this.state.currentEditCustomer}
                                  onCancel={this.closeUpdateCustomerModal}
                    />
                </FormModalWrapper>
                <NotificationWrapper ref={ref => this.notification = ref } />
            </Fragment>
        );
    }
}
export default withLayout(CustomersPage);