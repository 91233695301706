import React, { Component, Fragment } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { AppContext } from '../../context/AppProvider';
import Icon from '../Icon/Icon';
import { isAdmin, isCustomer } from '../_utils/user';
import tropicalLogo from '../../assets/images/tropical-brand-header.png';

export default class MainNavigation extends Component {
    state = {
        isActive: false
    };

    static contextType = AppContext;

    toggleMenu = event => {
        this.setState({isActive: !this.state.isActive});
    };

    logOut = () => {
        this.context.services.base.logout();
        this.props.history.replace('/login');
    };

    render() {
        return (
            <nav className="navbar" role="navigation" aria-label="main navigation">
                <div className="navbar-brand">
                    <Link className="navbar-item" to="/" style={{padding: 0}}>
                        <img src={tropicalLogo} alt="Tropical Casting" width="150" height="70" style={{maxHeight: 'initial'}}/>
                    </Link>

                    <div role="button"
                         className={`navbar-burger burger ${this.state.isActive ? 'is-active' : ''}`}
                         aria-label="menu"
                         aria-expanded="false"
                         data-target="mainNavigation"
                         onClick={this.toggleMenu}>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                    </div>
                </div>

                <div id="mainNavigation" className={`navbar-menu ${this.state.isActive ? 'is-active' : ''}`}>
                    <div className="navbar-start">
                        {isAdmin(this.props.user) || isCustomer(this.props.user)
                            ? <NavLink className="navbar-item" to="/projects/">
                                Proyectos
                            </NavLink>
                            : null
                        }
                        {isAdmin(this.props.user)
                            ? <Fragment>
                                <NavLink className="navbar-item" to="/profiles/">
                                    Perfiles
                                </NavLink>
                                <NavLink className="navbar-item" to="/customers/">
                                    Clientes
                                </NavLink>
                            </Fragment>
                            : null
                        }
                    </div>

                    <div className="navbar-end">
                        <div className="navbar-item">
                            <span className="icon has-text-info">
                                <Icon icon="user" />
                            </span>
                            <span>{this.props.user.username}</span>
                        </div>
                        <div className="navbar-item">
                            <div className="buttons">
                                <button onClick={this.logOut} className="button is-light">
                                    Log out
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        );
    }
}