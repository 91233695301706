import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PrivateRoute from '../components/HOC/PrivateRoute';
import HomePage from '../components/_pages/HomePage/HomePage';
import LoginPage from '../components/_pages/LoginPage/LoginPage';
import ProfilesPage from '../components/_pages/ProfilesPage/ProfilesPage';
import ProfilePage from '../components/_pages/ProfilePage/ProfilePage';
import CustomersPage from '../components/_pages/CustomersPage/CustomersPage';
import CustomerPage from '../components/_pages/CustomerPage/CustomerPage';
import ProjectPage from '../components/_pages/ProjectPage/ProjectPage';
import ProjectsPage from '../components/_pages/ProjectsPage/ProjectsPage';
import WorkbenchPage from '../components/_pages/WorkbenchPage/WorkbenchPage';
import UnauthorizedPage from '../components/_pages/UnauthorizedPage/UnauthorizedPage';
import Error404Page from '../components/_pages/Error404Page/Error404Page';

export default () => (
    <Switch>
        <Route path="/login/" component={LoginPage} />
        <PrivateRoute path="/" exact component={HomePage} />
        <PrivateRoute path="/unauthorized" exact component={UnauthorizedPage} />
        <PrivateRoute path="/workbench" exact component={WorkbenchPage} />
        <PrivateRoute path="/projects/" exact component={ProjectsPage} />
        <PrivateRoute path="/projects/:idProject" component={ProjectPage} />
        <PrivateRoute path="/profiles/" exact component={ProfilesPage} />
        <PrivateRoute path="/profiles/:idProfile" component={ProfilePage} />
        <PrivateRoute path="/customers/" exact component={CustomersPage} />
        <PrivateRoute path="/customers/:idCustomer" component={CustomerPage} />
        <Route component={Error404Page} />
    </Switch>
);