import BaseService from './BaseService';

export default class UserService extends BaseService {
    login(formData) {
        const endpoint = `login_check`;

        return this.fetch(`${this.API_URL}${endpoint}`, {
            method: 'POST',
            body: JSON.stringify(formData)
        }).then(response => {
            this.setToken(response.token);

            return Promise.resolve(response);
        });
    }

    createCustomer(username, email, phone) {
        const endpoint = `users/create/role-customer`;
        const data = new URLSearchParams();
        data.append('username', username);
        data.append('email', email);
        data.append('phone', phone);

        return this
            .fetch(`${this.API_URL}${endpoint}`,
                {
                    method: 'POST',
                    body: data
                },
                null,
                {'Content-Type': 'application/x-www-form-urlencoded'}
            )
            .then(response => response);
    }

    updateCustomer(idUser, phone) {
        const endpoint = `users/${idUser}/role-customer`;
        const data = new URLSearchParams();
        data.append('phone', phone);

        return this
            .fetch(`${this.API_URL}${endpoint}`,
                {
                    method: 'PUT',
                    body: data
                },
                null,
                {'Content-Type': 'application/x-www-form-urlencoded'}
            )
            .then(response => response);
    }

    updateEnable(idUser, enable) {
        const endpoint = `users/${idUser}/enable`;
        const data = new URLSearchParams();
        data.append('enable', enable);

        return this
            .fetch(`${this.API_URL}${endpoint}`,
                {
                    method: 'PUT',
                    body: data
                },
                null,
                {'Content-Type': 'application/x-www-form-urlencoded'}
            )
            .then(response => response);
    }

    createProfile(name, surname, email) {
        const endpoint = `users/create/role-user`;
        const data = new URLSearchParams();
        data.append('name', name);
        data.append('surname', surname);
        data.append('email', email);
        data.append('gender', '1');
        data.append('eyesColor', '1');

        return this
            .fetch(`${this.API_URL}${endpoint}`,
                {
                    method: 'POST',
                    body: data
                },
                null,
                {'Content-Type': 'application/x-www-form-urlencoded'}
            )
            .then(response => response);
    }

    findAll(page = 1) {
        const endpoint = 'users';

        return this
            .fetch(`${this.API_URL}${endpoint}`, {method: 'GET'}, {page})
            .then(response => response);
    }

    search(page = 1, q = null) {
        const endpoint = 'users/search/role-customer';

        return this
            .fetch(`${this.API_URL}${endpoint}`, {method: 'GET'}, {page, q})
            .then(response => response);
    }

    findById(idUser) {
        const endpoint = `users/${idUser}`;

        return this
            .fetch(`${this.API_URL}${endpoint}`, {method: 'GET'})
            .then(response => response);
    }

    findCustomers(page = 1, enabled = true) {
        const endpoint = 'users/role-customer';

        return this
            .fetch(`${this.API_URL}${endpoint}`, {method: 'GET'}, {page, enabled})
            .then(response => response);
    }

    findAdmins(page = 1, enabled = true) {
        const endpoint = 'users/role-admin';

        return this
            .fetch(`${this.API_URL}${endpoint}`, {method: 'GET'}, {page, enabled})
            .then(response => response);
    }

    findSuperAdmins(page = 1, enabled = true) {
        const endpoint = 'users/role-super-admin';

        return this
            .fetch(`${this.API_URL}${endpoint}`, {method: 'GET'}, {page, enabled})
            .then(response => response);
    }

    findProjects(idUser) {
        const endpoint = `users/${idUser}/projects`;

        return this
            .fetch(`${this.API_URL}${endpoint}`, {method: 'GET'})
            .then(response => response);
    }
}