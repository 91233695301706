import decode from 'jwt-decode';
const queryString = require('query-string');

export default class BaseService {
    constructor() {
        this.API_URL = process.env.REACT_APP_API_URL;
    }

    fetch(path, options, params = {}, headers = {'Accept': 'application/json', 'Content-Type': 'application/json'}, responseType = 'json') {
        const url = new URL(path);
        url.search = queryString.stringify(params);

        if (this.loggedIn()) {
            headers['Authorization'] = `Bearer ${this.getToken()}`;
        }

        return fetch(url.href, {
            headers,
            ...options
        })
            .then(this.checkStatus)
            .then(response =>
                response.status !== 204
                    ? responseType === 'blob'
                        ? response.blob()
                        : response.json()
                    : null
            );
    }

    loggedIn() {
        const token = this.getToken();

        return !!token && !this.isTokenExpired(token);
    }

    isTokenExpired(token) {
        try {
            const decoded = decode(token);
            return decoded.exp < Date.now() / 1000;
        } catch (err) {
            return false;
        }
    }

    setToken(idToken) {
        localStorage.setItem('id_token', idToken);
    }

    getToken() {
        return localStorage.getItem('id_token');
    }

    logout() {
        localStorage.removeItem('id_token');

    }

    decodeToken() {
        return decode(this.getToken());
    }

    checkStatus(response) {
        if (response.status >= 200 && response.status < 300) {
            return response;
        } else {
            let error = new Error(response.statusText);
            error.response = response;
            throw error;
        }
    }
}