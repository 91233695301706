import React, { Component } from 'react';
import Modal from 'react-responsive-modal';

export default class FormModalWrapper extends Component {
    static defaultProps = {
        title: '',
        onClose: () => {}
    };

    render() {
        return (
            <div className="modal-wrapper">
                <Modal {...this.props}
                       styles={{modal: {padding: 0, margin: '20px auto 0', borderRadius: '4px'}}}
                       showCloseIcon={false}>
                    <div className="modal-card">
                        <header className="modal-card-head">
                            <p className="modal-card-title">{this.props.title}</p>
                            <button className="delete"
                                    aria-label="close"
                                    onClick={this.props.onClose}
                            />
                        </header>
                        {this.props.children}
                    </div>
                </Modal>
            </div>
        );
    }
}