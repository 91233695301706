import React, { Component, Fragment } from 'react';
import { AppContext } from '../../../context/AppProvider';
import Breadcrumb from '../../Breadcrumb/Breadcrumb';
import { withLayout } from '../../HOC/WithLayout';
import Hero from '../../Hero/Hero';
import Icon from '../../Icon/Icon';
import LoadMaskWrapper from '../../_wrappers/LoadMaskWrapper';
import ProjectProfile from '../../ProjectProfile/ProjectProfile';
import Button from '../../Button/Button';
import saveAs from 'file-saver';
import NotificationWrapper from '../../_wrappers/NotificationWrapper';
import AsideBlock from '../../AsideBlock/AsideBlock';
import { Link } from 'react-router-dom';
import { isAdmin } from '../../_utils/user';
import moment from 'moment';

class ProjectPage extends Component {
    state = {
        name: '',
        profiles: [],
        pagination: {},
        loading: true,
        downloadingAll: false,
        downloadingSelected: false,
        downloadSelectedPDFListing: false,
        downloadingFavorite: false,
        removingAll: false
    };
    idProject = this.props.match.params.idProject;

    static contextType = AppContext;

    componentDidMount() {
        this.findProject(this.idProject);
    }

    findProject(idProject) {
        this.context.services.project
            .findById(idProject)
            .then(project => {
                if (!project) {
                    this.setState({loading: false});
                    return;
                }

                const profiles = project.users
                    .filter(user => !user.roles.some(role => role === 'ROLE_CUSTOMER' || role === 'ROLE_ADMIN' || role === 'ROLE_SUPER_ADMIN'))
                    .map(user => user.profile);

                this.setState({
                    name: project.name,
                    favorites: project.favorites,
                    selected: project.selected,
                    profiles,
                    loading: false,
                    active: project.active
                });
            }).catch(reason => {
                this.props.history.push({pathname: '/unauthorized', state: reason.message});
            });
    }

    downloadAllPDF = event => {
        this.setState({downloadingAll: true});
        this.context.services.project
            .downloadPDF(this.idProject)
            .then(response => {
                const dateString = moment().format('DD-MM-YYYY-HH-mm-ss');
                const filename = `proyecto_${this.idProject}_todos_${dateString}.pdf`;
                saveAs(response, filename);
                this.setState({downloadingAll: false});
            })
            .catch(reason => {
                this.notification.board.addNotification({
                    title: 'Error descargando PDF',
                    content: reason.message,
                    className: 'danger'
                });
                this.setState({downloadingAll: false});
            });
    };

    downloadSelectedPDF = event => {
        this.setState({downloadingSelected: true});
        this.context.services.project
            .downloadSelectedPDF(this.idProject)
            .then(response => {
                const dateString = moment().format('DD-MM-YYYY-HH-mm-ss');
                const filename = `proyecto_${this.idProject}_seleccionados_${dateString}.pdf`;
                saveAs(response, filename);
                this.setState({downloadingSelected: false});
            })
            .catch(reason => {
                this.notification.board.addNotification({
                    title: 'Error descargando PDF',
                    content: reason.message,
                    className: 'danger'
                });
                this.setState({downloadingSelected: false});
            });
    };

    downloadSelectedPDFListing = event => {
        this.setState({downloadSelectedPDFListing: true});
        this.context.services.project
            .downloadSelectedPDFListing(this.idProject)
            .then(response => {
                const dateString = moment().format('DD-MM-YYYY-HH-mm-ss');
                const filename = `proyecto_${this.idProject}_datos-seleccionados_${dateString}.pdf`;
                saveAs(response, filename);
                this.setState({downloadSelectedPDFListing: false});
            })
            .catch(reason => {
                this.notification.board.addNotification({
                    title: 'Error descargando PDF',
                    content: reason.message,
                    className: 'danger'
                });
                this.setState({downloadSelectedPDFListing: false});
            });
    };

    downloadFavoritePDF = event => {
        this.setState({downloadingFavorite: true});
        this.context.services.project
            .downloadFavoritePDF(this.idProject)
            .then(response => {
                const dateString = moment().format('DD-MM-YYYY-HH-mm-ss');
                const filename = `proyecto_${this.idProject}_favoritos_${dateString}.pdf`;
                saveAs(response, filename);
                this.setState({downloadingFavorite: false});
            })
            .catch(reason => {
                this.notification.board.addNotification({
                    title: 'Error descargando PDF',
                    content: reason.message,
                    className: 'danger'
                });
                this.setState({downloadingFavorite: false});
            });
    };

    removeAll = event => {
        const idsProfiles = this.state.profiles.map(profile => profile.idProfile);

        this.setState({removingAll: true});
        this.context.services.project
            .removeAllProfiles(this.idProject, idsProfiles)
            .then(response => {
                if (!response.success) return;
                this.setState({removingAll: false});
            }).catch(reason => {
                this.notification.board.addNotification({
                    title: 'Error desasociando todos los perfiles del proyecto',
                    content: reason.message,
                    className: 'danger'
                });
                this.setState({removingAll: false});
            })
            .finally(() => this.findProject(this.idProject));
    };

    toggleActive = (event, idProject, active) => {
        event.preventDefault();

        this.context.services.project
            .toggleActive(idProject, active)
            .then(response => {
                if (!response.success) return;

                if (active) {
                    this.notification.board.addNotification({
                        title: 'Proyecto Actualizado',
                        content: 'El proyecto se ha activado',
                        className: 'success'
                    });
                } else {
                    this.notification.board.addNotification({
                        title: 'Proyecto Actualizado',
                        content: 'El proyecto se ha desactivado',
                        className: 'warning'
                    });
                }

                this.findProject(idProject);
            });
    };

    render() {
        return (
            <Fragment>
                <Hero title="Selección de perfiles" subtitle={this.state.name} />
                <section className="section">
                    <div className="container">
                        <Breadcrumb model={[
                            {name: 'Listado de proyectos', link: '/projects/'},
                            {name: `${this.state.name}`, link: './', active: true}]}
                        />
                        <div className="columns">
                            <div className="column is-one-quarter-widescreen is-one-third-desktop is-one-third-tablet">
                                {isAdmin(this.props.user)
                                    ? <AsideBlock title="Activación de proyecto">
                                        <div>
                                            <p className="mb-1 has-text-grey">
                                                Proyecto {this.state.active ? 'activo' : 'inactivo'}
                                            </p>
                                            <Button icon="unlock"
                                                    extraClasses={this.state.active ? 'is-warning' : 'is-success'}
                                                    text={this.state.active ? 'Desactivar' : 'Activar'}
                                                    onClick={event => this.toggleActive(event, this.idProject, !this.state.active)}
                                            />
                                        </div>
                                    </AsideBlock>
                                    : null
                                }
                                <AsideBlock title="Todos los perfiles">
                                    <Button icon={`${this.state.downloadingAll ? 'sync-alt' : 'file-pdf'}`}
                                            spin={this.state.downloadingAll}
                                            disabled={!this.state.profiles.length}
                                            extraClasses="is-info"
                                            text={`${this.state.downloadingAll ? 'Descargando…' : 'Descargar PDF'}`}
                                            onClick={this.downloadAllPDF}
                                    />
                                    {isAdmin(this.props.user)
                                        ? <div className="mt-1">
                                            <Button icon={`${this.state.removingAll ? 'sync-alt' : 'fas fa-user-times'}`}
                                                    spin={this.state.removingAll}
                                                    disabled={!this.state.profiles.length}
                                                    extraClasses="is-danger"
                                                    text={`${this.state.removingAll ? 'Quitando perfiles…' : 'Quitar todos'}`}
                                                    onClick={event => {
                                                        if (!window.confirm('¿Seguro que quieres desasociar todos los perfiles del proyecto?')) return;
                                                        this.removeAll(event);
                                                    }}
                                            />
                                        </div>
                                        : null
                                    }
                                </AsideBlock>
                                <AsideBlock title="Perfiles seleccionados">
                                    {isAdmin(this.props.user)
                                        ? <table className="table is-bordered is-hoverable is-fullwidth is-narrow table--align-middle table--mini-table">
                                            <thead>
                                                <tr>
                                                    <th>Nombre</th>
                                                    <th style={{textAlign: 'center'}}>Ficha</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.selected && this.state.selected.map(profile => (
                                                    <tr key={profile.idProfile}>
                                                        <td>
                                                            {profile.name} {profile.surname}
                                                        </td>
                                                        <td style={{textAlign: 'center'}}>
                                                            <Link className="show-tooltip button is-white"
                                                                  data-tooltip="Ver ficha"
                                                                  to={`/profiles/${profile.idProfile}`}>
                                                                <Icon icon="arrow-circle-right" />
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td className="has-text-grey" colSpan={1000}>
                                                        {this.state.selected && this.state.selected.length
                                                            ? `${this.state.selected.length} perfiles seleccionados`
                                                            : 'No se encuentran resultados…'
                                                        }
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                        : null
                                    }
                                    <Button icon={`${this.state.downloadingSelected ? 'sync-alt' : 'file-pdf'}`}
                                            spin={this.state.downloadingSelected}
                                            disabled={this.state.selected && !this.state.selected.length}
                                            extraClasses="is-info"
                                            text={`${this.state.downloadingSelected ? 'Descargando…' : 'Descargar PDF público'}`}
                                            onClick={this.downloadSelectedPDF}
                                    />
                                    {isAdmin(this.props.user) &&
                                    <div className="mt-1">
                                        <Button icon={`${this.state.downloadSelectedPDFListing ? 'sync-alt' : 'file-pdf'}`}
                                                spin={this.state.downloadSelectedPDFListing}
                                                disabled={this.state.selected && !this.state.selected.length}
                                                extraClasses="is-info"
                                                text={`${this.state.downloadSelectedPDFListing ? 'Descargando…' : 'Descargar PDF detalle'}`}
                                                onClick={this.downloadSelectedPDFListing}
                                        />
                                    </div>}
                                </AsideBlock>
                                <AsideBlock title="Perfiles favoritos">
                                    {isAdmin(this.props.user)
                                        ? <table className="table is-bordered is-hoverable is-fullwidth is-narrow table--align-middle table--mini-table">
                                            <thead>
                                                <tr>
                                                    <th>Nombre</th>
                                                    <th style={{textAlign: 'center'}}>Ficha</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.favorites && this.state.favorites.map(profile => (
                                                    <tr key={profile.idProfile}>
                                                        <td>
                                                            {profile.name} {profile.surname}
                                                        </td>
                                                        <td style={{textAlign: 'center'}}>
                                                            <Link className="show-tooltip button is-white"
                                                                  data-tooltip="Ver ficha"
                                                                  to={`/profiles/${profile.idProfile}`}>
                                                                <Icon icon="arrow-circle-right" />
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td className="has-text-grey" colSpan={1000}>
                                                        {this.state.favorites && this.state.favorites.length
                                                            ? `${this.state.favorites.length} perfiles favoritos`
                                                            : 'No se encuentran resultados…'
                                                        }
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                        : null
                                    }
                                    <Button icon={`${this.state.downloadingFavorite ? 'sync-alt' : 'file-pdf'}`}
                                            spin={this.state.downloadingFavorite}
                                            disabled={this.state.favorites && !this.state.favorites.length}
                                            extraClasses="is-info"
                                            text={`${this.state.downloadingFavorite ? 'Descargando…' : 'Descargar PDF'}`}
                                            onClick={this.downloadFavoritePDF}
                                    />
                                </AsideBlock>
                            </div>
                            <div className="column is-four-quarters-widescreen is-four-thirds-desktop is-two-thirds-tablet">
                                {this.state.profiles.length
                                    ? <Fragment>
                                        {this.state.profiles.map(profile => (
                                            <div key={profile.idProfile}
                                                 className="column">
                                                <ProjectProfile user={this.props.user}
                                                                idProject={this.idProject}
                                                                profile={profile}
                                                                isFavorite={this.state.favorites.some(favorite => favorite.idProfile === profile.idProfile)}
                                                                isSelected={this.state.selected.some(selected => selected.idProfile === profile.idProfile)}
                                                                onToggleFavorite={() => this.findProject(this.idProject)}
                                                                onToggleSelected={() => this.findProject(this.idProject)}
                                                                onRemove={() => {
                                                                    this.findProject(this.idProject);
                                                                    this.notification.board.addNotification({
                                                                        title: 'Éxito',
                                                                        content: 'Perfil eliminado del proyecto',
                                                                        className: 'warning'
                                                                    });
                                                                }}
                                                />
                                                <hr />
                                            </div>
                                        ))}
                                    </Fragment>
                                    : <div className="message is-info">
                                        <div className="message-body">
                                            <p><Icon icon="clock" /> No se encuentran perfiles…</p>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </section>
                <LoadMaskWrapper visible={this.state.loading}
                                 backgroundLayerStyle={{ position: 'fixed' }}>
                    <h3>Cargando proyectos…</h3>
                </LoadMaskWrapper>
                <NotificationWrapper ref={ref => this.notification = ref } />
            </Fragment>
        );
    }
}
export default withLayout(ProjectPage);
