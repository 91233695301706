import React, { Component } from 'react';
import { AppContext } from '../../context/AppProvider';
import Input from '../Form/Input';
import FieldWrapper from '../Form/FieldWrapper';
import Icon from '../Icon/Icon';
import Button from '../Button/Button';

export default class LoginForm extends Component {
    state = {
        formData: {
            _username: '',
            _password: ''
        },
        error: undefined,
        sending: false
    };

    USER_SYSTEM_URL = process.env.REACT_APP_USER_SYSTEM_URL;

    static defaultProps = {
        onSuccess: () => {}
    };

    static contextType = AppContext;

    handleChange = field => {
        const formData = Object.assign(this.state.formData, {[field.name]: field.value});
        this.setState({formData});
    };

    handleSubmit = event => {
        event.preventDefault();

        this.setState({
            error: undefined,
            sending: true
        });
        this.context.services.user
            .login(this.state.formData)
            .then(response => {
                this.setState({sending: false}, () => {
                    this.props.onSuccess(response);
                });
            })
            .catch(response => {
                if (!response.hasOwnProperty('response')) {
                    this.setState({
                        error: true,
                        sending: false
                    });
                    return;
                }

                this.setState({
                    error: {
                        code: response.response.status,
                        message: response.response.statusText
                    },
                    sending: false
                });
            });
    };

    render() {
        return (
            <div className="card">
                <div className="card-header card-header--form-caption">
                    Accede
                </div>
                <div className="card-content">
                    {this.state.error
                        ? <article className="message is-danger">
                            <div className="message-body">
                                <Icon icon="exclamation-triangle" /> {this.state.error && this.state.error.hasOwnProperty('code') && this.state.error.code === 401 ? 'Acceso no permitido' : 'Error desconocido' }
                            </div>
                        </article>
                        : null
                    }
                    <form onSubmit={this.handleSubmit}>
                        <FieldWrapper label="E-mail">
                            <Input type="email"
                                   name="_username"
                                   placeholder="E-mail"
                                   iconLeft="envelope"
                                   helpText="Introduce una dirección de e-mail válida"
                                   onChange={this.handleChange}
                                   value={this.state.formData._username}
                            />
                        </FieldWrapper>
                        <FieldWrapper label="Password">
                            <Input type="password"
                                   name="_password"
                                   placeholder="Password"
                                   iconLeft="lock"
                                   onChange={this.handleChange}
                                   value={this.state.formData._password}
                            />
                        </FieldWrapper>
                        <FieldWrapper>
                            <Button icon={`${this.state.sending ? 'sync-alt' : ''}`}
                                    spin={this.state.sending}
                                    disabled={this.state.sending}
                                    extraClasses="is-primary"
                                    type="submit"
                                    text={`${this.state.sending ? 'Por favor, espera…' : 'Login'}`}
                            />
                        </FieldWrapper>
                    </form>
                </div>
                <footer className="card-footer">
                    <div className="card-footer-item">
                        <a href={`${this.USER_SYSTEM_URL}resetting/request`}>Recuperar contraseña</a>
                    </div>
                </footer>
            </div>
        );
    }
}