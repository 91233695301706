import React, { Component, Fragment } from 'react';
import { AppContext } from '../../context/AppProvider';
import Icon from '../Icon/Icon';
import ComboBoxWrapper from '../_wrappers/ComboBoxWrapper';
import LoadMaskWrapper from '../_wrappers/LoadMaskWrapper';
import './project-selector.scss';

export default class ProjectSelector extends Component {
    static defaultProps = {
        onUpdateUser: () => {}
    };

    state = {
        projects: [],
        associatedProjects: []
    };

    static contextType = AppContext;

    componentDidMount() {
        this.findList();
        this.findByUser(this.props.idUser);
    }

    findList() {
        this.context.services.project
            .findList()
            .then(projects => this.setState({projects}));
    }

    findByUser(idUser) {
        this.context.services.project
            .findByUser(idUser)
            .then(projects => {
                this.setState({associatedProjects: projects || []});
            });
    }

    addUser = (idProject, idUser, associatedProjects) => {
        const alreadyExists = associatedProjects.some(associatedProject => associatedProject.idProject === idProject);
        if (alreadyExists) return;

        this.context.services.project
            .addUser(idProject, idUser)
            .then(response => {
                if (!response.success) return;
                this.findByUser(idUser);
                this.props.onUpdateUser();
            });
    };

    removeUser = (idProject, idUser) => {
        this.context.services.project
            .removeUser(idProject, idUser)
            .then(response => {
                if (!response.success) return;
                this.findByUser(this.props.idUser);
                this.props.onUpdateUser();
            });
    };

    render() {
        return (
            <div className="project-selector">
                {this.state.projects.length
                    ? <Fragment>
                        <ComboBoxWrapper idProperty="idProject"
                                         displayProperty="name"
                                         placeholder="Busca y selecciona el proyecto para asociarlo al perfil…"
                                         value={null}
                                         clearIcon={false}
                                         toggleExpandOnClick
                                         dataSource={this.state.projects}
                                         onOptionSelected={idProject => this.addUser(idProject, this.props.idUser, this.state.associatedProjects)}
                        />

                        <ul className="pairs mt-3">
                            <li className="pairs__pair">
                                <div className="pairs__key">Proyectos asociados</div>
                                <div className="pairs__value">
                                    {this.state.associatedProjects.length
                                        ? this.state.associatedProjects.map((associatedProject, index) => (
                                            <div className="level"
                                                 key={index}>
                                                <div className="level-left">
                                                    {associatedProject.name}
                                                </div>
                                                <div className="level-right">
                                                    <button className="delete"
                                                            onClick={event => this.removeUser(associatedProject.idProject, this.props.idUser)} />
                                                </div>
                                            </div>
                                        ))
                                        : <article className="message is-info">
                                            <div className="message-body">
                                                <p><Icon icon="clock" /> No hay proyectos asociados…</p>
                                            </div>
                                        </article>
                                    }
                                </div>
                            </li>
                        </ul>
                    </Fragment>
                    : <LoadMaskWrapper visible={!this.state.projects.length}>
                        <h3>Cargando proyectos…</h3>
                    </LoadMaskWrapper>
                }
            </div>
        );
    }
}