import React, { Component } from 'react';
import Icon from '../Icon/Icon';

export default class Button extends Component {
    static defaultProps = {
        tooltip: null,
        extraClasses: '',
        spin: false,
        onClick: () => {}
    };

    render() {
        return (
            <button className={`button ${this.props.extraClasses}`}
                    disabled={this.props.disabled}
                    type={this.props.type}
                    data-tooltip={this.props.tooltip}
                    onClick={this.props.onClick}>
                {this.props.icon
                    ? <span className="icon">
                        <Icon spin={this.props.spin} icon={this.props.icon} />
                    </span>
                    : null
                }
                {this.props.text
                    ? <span className="button__text">
                        {this.props.text}
                    </span>
                    : null
                }
            </button>
        );
    }
}